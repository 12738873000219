import React, { createContext, useContext, useMemo } from 'react';

const defaultValue = {
	getToken: undefined,
};

type TokenStorage = {
	getToken: (() => string | undefined) | undefined;
};

export const TokenContext = createContext<TokenStorage>(defaultValue);

export const useGetToken = () => useContext(TokenContext)?.getToken;

export function TokenContextProvider({ children, getToken }: { children: React.ReactNode } & TokenStorage) {
	const value = useMemo(() => ({ getToken }), [getToken]);

	return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>;
}
