import { getUrl, NextFetchRequestInit, FetchInstanceParams, getFetchOptions } from './utils';
import { useGetToken } from './TokenContext';

export const useCustomInstance = <T>(): ((
	fetchParams: FetchInstanceParams,
	requestOptions?: NextFetchRequestInit,
) => Promise<T>) => {
	const getToken = useGetToken();

	return async (fetchParams: FetchInstanceParams, requestOptions?: NextFetchRequestInit) => {
		const token = requestOptions?.insecure ? null : getToken?.();
		let params = fetchParams;
		if (token) {
			params = {
				...fetchParams,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};
		}
		// todo pokud by doslo k invalidaci access_tokenu tak bychom na to meli reagovat tim ze ho na fe jakoby expirujeme a on se pak pokusi obnovit v ramci dalsiho requestu
		// mela by tu asi byt nejaka reakce na 403
		const response = await fetch(getUrl(params), getFetchOptions(params, requestOptions));
		if (!response.ok) {
			try {
				const body = await response.json();
				return Promise.reject({ ...body, status: response.status, fetchParams });
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
			} catch (error) {
				return Promise.reject({ message: 'server error', status: response.status, fetchParams });
			}
		}

		return response.json();
	};
};
